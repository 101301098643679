/**
 * Import critical Resources (navigation for example) directly in this entry file
 */

/**
 * Imports async Js files from /components folder
 * Usage:   Create a Class inside /components/{component-name}/index.js
 *          On DOM element set data-component="{component-name}"
 */

function createComponents() {
    const COMPONENT_ATTR = 'data-component';
    const COMPONENTS = document.querySelectorAll(`[${COMPONENT_ATTR}]`);

    COMPONENTS.forEach((component) => {
        const COMPONENT_NAME = component.getAttribute(COMPONENT_ATTR);
        import(`./components/${COMPONENT_NAME}/index.js`).then(c => {
            new c.default({el: component });
        });
    });
}

createComponents();
